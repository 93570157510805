<template>
    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="color" id="vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M22.4386 6.69457C23.1871 7.41554 23.1871 8.58446 22.4386 9.30543L16.0497 15.4593C15.3012 16.1802 14.0877 16.1802 13.3392 15.4593C12.5907 14.7383 12.5907 13.5694 13.3392 12.8484L16.4561 9.84615L1.91667 9.84615C0.85812 9.84615 2.86702e-07 9.0196 2.54292e-07 8C2.21882e-07 6.9804 0.85812 6.15385 1.91667 6.15385L16.4561 6.15385L13.3392 3.15158C12.5907 2.43061 12.5907 1.26169 13.3392 0.540726C14.0877 -0.180242 15.3012 -0.180242 16.0497 0.540726L22.4386 6.69457Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
  name: 'Line1',
  props: {
    color: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="css" scoped>
</style>